import React, {Component} from "react"
import { Link } from "gatsby"

import bcLogo from "../images/bcLogo.png"

class Navbar extends Component {
  componentDidMount() {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  }
 render () {
  return(
      <nav className="navbar is-flex is-primary" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand navbar-center">
            <Link to="/"><img src={bcLogo} alt="BuilderCover Logo" /></Link>
            <div className="navbar-burger burger navbar-center" data-target="navMenu">
              <span/>
              <span/>
              <span/>
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
            <div className="navbar-end">
              <Link to="/how-it-works" className="navbar-item">How It Works</Link>
              <Link to="/services" className="navbar-item">Available Services</Link>
              <Link to="/contact" className="navbar-item">Contact</Link>
              <div className="navbar-item">
                <div className="field is-grouped">
                  <p className="control">
                    <Link className="button is-info" to="/request">Request Services</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
  )}}


export default Navbar