/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"

import Navbar from "./navbar"
import bcLogo from "../images/bcLogo.png"

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar/>
      <main>{children}</main>
      <footer className="footer is-paddingless">
        <div className="container">
          <div className="columns">
            <div className="column terms">
              <ul>
                <li><Link to="/terms">Terms and Conditions</Link> | <Link to="/privacy">Privacy</Link></li>
                <li>© {new Date().getFullYear()} BuilderCover</li>
              </ul>
            
            </div>
            <div className="footer-logo">
              <img src={bcLogo} alt="BuilderCover Logo" />
            </div>            
          </div>
          
        </div>
      </footer>
    </div>
  )
}

export default Layout
